import Evaporate from 'evaporate'
import sparkMD5 from 'spark-md5'
import sha256 from 'js-sha256'

export function createUploader(signerUrl, accessKey, bucket) {
  const obj = Evaporate.create({
    signerUrl: signerUrl,
    aws_key: accessKey,
    bucket: bucket,
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: sha256,
    partSize: 20 * 1024 * 1024,
    s3Acceleration: true,
    s3FileCacheHoursAgo: 24,
  })
  return obj
}

export function getFileMeta(file) {
  return new Promise(function (resolve) {
    let meta = {
      name: file.name,
      size: file.size,
      errors: [],
    }

    // No other validation matters if the file is empty.
    if (file.size == 0) {
      meta['errors'].push('The file is empty.')
      resolve(meta)
      return
    }

    if (file.type.match(/^image\//)) {
      getImageDimensions(file).then(function ([width, height, error]) {
        meta['width'] = width
        meta['height'] = height
        if (error) meta['errors'].push(error)
        resolve(meta)
      })
    } else {
      resolve(meta)
    }
  })
}

function getImageDimensions(file) {
  return new Promise(function (resolve) {
    let img = new Image()
    img.onload = function () {
      resolve([this.width, this.height, null])
    }
    img.onerror = function () {
      resolve([null, null, 'Image does not seem to be valid.'])
    }
    img.src = URL.createObjectURL(file)
  })
}
